<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">省份:</div>
            <el-select v-model="provinceId" placeholder="请选择" class="select_container" @change="onProvince()">
                <el-option
                        v-for="item in this.$store.state.school.province.list"
                        :key="item.id"
                        :label="item.provinceName"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">城市:</div>
            <el-select v-model="cityId" placeholder="请选择" class="select_container" @change="onCity()">
                <el-option
                        v-for="item in cityList"
                        :key="item.id"
                        :label="item.cityName"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">县区:</div>
            <el-select v-model="areaId" placeholder="请选择" class="select_container" @change="onProvince()">
                <el-option
                        v-for="item in areaList"
                        :key="item.id"
                        :label="item.areaName"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">学校名称:</div>
            <el-input class="manage_input" type="text" size="small" v-model="schoolName"></el-input>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="onCancle">取消</el-button>

    </div>

</template>

<script>
    export default {
        name: "add",

        data() {
            return {
                provinceId: '',//省份id
                cityId: '',//城市id
                cityList: '',//城市列表
                areaId: '',//区域id
                areaList: '',//区域列表
                schoolName: '',//学校名称
            }
        },
        mounted() {
            this.$store.dispatch("school/provinceList");
        },
        methods: {
            //确认添加
            onConfirm() {
                if (!this.schoolName) {
                    this.$message.warning('请输入小学名称');
                } else {
                    this.$store.dispatch("school/addSchool", {
                        'areaId': this.areaId,
                        'schoolName': this.schoolName
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }

            },
            //取消添加
            onCancle() {
                this.$router.go(-1);
            },
            //省份变化
            onProvince() {
                console.log(this.provinceId);
                this.$store.dispatch("school/cityList", {'id': this.provinceId}).then((data) => {
                    this.cityList = data
                });
            },
            //城市变化
            onCity() {
                this.$store.dispatch("school/areaList", {'id': this.cityId,}).then((data) => {
                    this.areaList = data
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .checkbox_container {
            padding: 30px 100px 20px 100px;
            width: 100%;

            .checkList {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 30px;
            }

        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

    }

</style>
